<nav class="w-full bg-white px-4" [ngClass]="(staticNav$ | async) ? '' : 'fixed border-b shadow-sm'">
  <div class="container max-w-8xl h-16 justify-between items-center">
    <div class="flex gap-2 flex-1">
      <a routerLink="/">
        <img class="h-8 hidden xs:block" src="https://arq.pw/public/long-black-2.svg" alt="logo" />
        <img class="h-8 block xs:hidden" src="https://arq.pw/public/icon-black.svg" alt="logo" />
      </a>
    </div>

    <div class="flex gap-8 font-semibold">
      <a class="link" routerLink="/docs" routerLinkActive="active"> Docs </a>
      <a class="link" [href]="loginUrl" target="_blank">Sign in</a>
    </div>
  </div>
</nav>

<router-outlet />

<div class="text-slate-500 right-2 p-2">
  <a class="hover:text-blue-500" [href]="projectUrl + buildId" target="_blank">build {{ buildId.slice(0, 7) }}</a>
</div>
